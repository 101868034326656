.command {
    position: relative;


    &__wrapper {
        display: flex;
        justify-content: space-between;

        @media (max-width: 992px) {
            flex-direction: column;
        }
    }

    &__persons {
        margin-right: 15px;
        max-width: 470px;

        @media (max-width: 992px) {
            margin-right: initial;
            margin-bottom: 20px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            max-width: initial;
            grid-gap: 15px;
        }

        @media (max-width: 576px) {
            display: block;
        }
    }

    &__person + &__person {
        @media (max-width: 576px) {
            margin-top: 15px;
        }
    }

    &__text {
        margin-top: 20px;
        margin-bottom: 25px;

        @media (max-width: 992px) {
            margin-top: 15px;
            margin-bottom: 15px;
        }
    }

    &__title {
        font-family: "Audiowide", sans-serif;
        font-size: fluid-font(2.4rem, 1.8rem);
        line-height: 160%;
    }

    &__person {
        font-size: 1.6rem;
    }

    &__education {
        margin-right: 15px;

        @media (max-width: 992px) {
            margin-right: initial;
        }
    }

    &__person + &__person {
        margin-top: 35px;

        @media (max-width: 992px) {
            margin-top: initial;
        }
    }

    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        opacity: 0.9;

        span {
            font-weight: 700;
        }

        @media (max-width: 992px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 50px;
        align-items: flex-start;

        @media (max-width: 1200px) {
            grid-gap: 15px;
        }

        @media (max-width: 992px) {
            max-width: initial;
        }

        @media (max-width: 576px) {
            display: block;
        }
    }

    &__card {
        padding: 15px 10px 30px;
        background-image: linear-gradient(50.58deg, #080924 -3.93%, #151638 106.84%);
        box-shadow: -5px -10px 50px rgba(219, 219, 230, 0.1), 12px 20px 35px rgba(3, 3, 13, 0.8);
        border-radius: 5px;

        &-title {
            margin-top: 20px;
            font-family: "Audiowide", sans-serif;
            font-size: fluid-font(2.1rem, 1.8rem);
            line-height: 130%;
            opacity: 0.9;
            text-align: center;
        }
    }

    &__card + &__card {
        @media (max-width: 576px) {
            margin-top: 15px;
        }
    }

    &__img {
        position: relative;
        padding-top: 100%;
        border-radius: 4px;

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__decor {
        position: absolute;
        left: -200px;
        top: 200px;
        width: 667px;
        height: 667px;
        border-radius: 160px;
        z-index: -2;
        background-image: linear-gradient(50.58deg, #080924 -3.93%, #151638 106.84%);
        box-shadow: 20px 40px 100px rgba(4, 4, 15, 0.75), -40px -15px 150px rgba(170, 171, 200, 0.15);
        transform: rotate(23deg);

        @media (max-width: 992px) {
            display: none;
        }
    }
}