//<МИКСИНЫ>===================================
@import "./settings/mixins";
//</МИКСИНЫ>======================================

//ФУНКЦИИ===================================
@import "./settings/functions";
//</ФУНКЦИИ>======================================

//<ШРИФТЫ>======================================

//<Подключаем шрифты>===================
@import "./settings/fonts";
//</Подключаем шрифты>==================

//<Иконочные шрифты>======================================

//</Иконочные шрифты>=====================================

//<Шрифт по умолчанию>============================
$font-family: "GothamPro", sans-serif;
//</Шрифт по умолчанию>=============================

//</ШРИФТЫ>======================================

//<ПЕРЕМЕННЫЕ>============================

// Ширина макета для адаптивного шрифта (адаптация по пикселям)
$maxWidth: 1920;

// Ширина контейнера
$mw: 120;

// Длительность свойства transition
$ms: 0.3s;

//</ПЕРЕМЕННЫЕ>============================

//<ОБНУЛЕНИЕ, ОБЩИЕ ПАРАМЕТРЫ>===============================================================================================
:root {
    --clr-default-100: #fff;
    --clr-default-800: #2f2f33;
    --clr-default-900: #000;
    --clr-primary-400: #fad442;
    --clr-primary-500: #ef872c;
    --clr-bg-900: #080924;
}
@import "./settings/normalize";

body {
    font-family: $font-family;
    font-weight: 400;
    font-size: 1.8rem;
    color: var(--clr-default-100);
    background-color: var(--clr-bg-900);

    &.lock {
        overflow: hidden;

        &--wrapper {
            .wrapper::before {
                opacity: 1;
                visibility: visible;
                transition: opacity $ms, visibility $ms;
            }
        }
    }
}

//</ОБНУЛЕНИЕ, ОБЩИЕ ПАРАМЕТРЫ>===============================================================================================

//<ОБОЛОЧКА>===========================================================================================================
.wrapper {
    overflow: hidden;
    width: 100%;
    min-width: 320px;
    min-height: 100%;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 5;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;
        visibility: hidden;
        transition: opacity $ms, visibility $ms;
    }
}

//</ОБОЛОЧКА>===========================================================================================================

//<ОСНОВНАЯ СЕТКА>===========================================================================================================
.container {
    margin: 0 auto;
    padding: 0 15px;
    width: 100%;
    max-width: $mw + rem;
}

//</ОСНОВНАЯ СЕТКА>===========================================================================================================
// Подключение общих элементов
@import "ui";
// Подключение общих элементов

//<ПОДКЛЮЧЕНИЕ ФАЙЛОВ СТИЛЕЙ СТРАНИЦ И ЭЛЕМЕНТОВ>====================================================================================================
@import "./components/ui/header.scss";

@import "./components/indexPage/hero";
@import "./components/indexPage/collections";
@import "./components/indexPage/command";
@import "./components/indexPage/teachers";
@import "./components/indexPage/mission";
@import "./components/indexPage/network";
@import "./components/indexPage/artists";

@import "./components/ui/footer.scss";
//</ПОДКЛЮЧЕНИЕ ФАЙЛОВ СТИЛЕЙ СТРАНИЦ И ЭЛЕМЕНТОВ>====================================================================================================
