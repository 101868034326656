.hero {
    padding-top: 90px;


    &__text {
        line-height: 160%;

        p + p {
            margin-top: 30px;
        }

        p {
            @media (max-width: 767px) {
                text-align: center;
            }
        }
    }

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 767px) {
            flex-direction: column;
        }
    }

    &__info {
        flex: 0 1 49%;
        max-width: 527px;
        margin-right: 15px;
        @media (max-width: 767px) {
            max-width: initial;
            margin-right: initial;
        }
    }

    &__title {
        margin-bottom: 35px;
        font-family: "Audiowide", sans-serif;
        font-size: fluid-font(5rem, 3rem);
        line-height: 120%;

        span {
            color: var(--clr-primary-500);
        }

        @media (max-width: 767px) {
            margin-bottom: 20px;
            text-align: center;
        }
    }

    &__btns {
        margin-top: 45px;
        display: flex;
        align-items: center;


        @media (max-width: 767px) {
            margin-top: 20px;
            justify-content: center;
        }

        @media (max-width: 576px) {
            flex-direction: column;
        }
    }

    .section__btn + .section__btn {
        @media (max-width: 576px) {
            margin-top: 15px;
        }
    }

    .section__btn--yellow {
        min-width: 225px;
    }

    .section__btn--blue {
        margin-left: 30px;
        min-width: 180px;

        @media (max-width: 576px) {
            margin-left: initial;
        }
    }

    &__decor {
        position: absolute;
        left: -450px;
        top: -90%;
        z-index: -2;
        width: 1125px;
        height: 1125px;
        background-image: linear-gradient(50.58deg, #080924 -3.93%, #151638 106.84%);
        box-shadow: 40px 60px 150px rgba(4, 4, 15, 0.75), -15px -20px 60px rgba(170, 171, 200, 0.12);
        border-radius: 300px;
        transform: rotate(45deg);

        @media (max-width: 992px) {
            display: none;
        }
    }

    &__img {
        flex: 0 1 49%;
        position: relative;
        max-width: 474px;
        z-index: 1;

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
        }

        @media (max-width: 767px) {
            margin-top: 20px;
            max-width: 350px;
        }
    }
}
