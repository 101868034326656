.collections {
    position: relative;
    padding-top: 50px;
    padding-bottom: 95px;

    @media (max-width: 992px) {
        padding-top: 70px;
    }


    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 55px 25px;
        background-color: var(--clr-bg-900);

        &--reverse {
            flex-direction: row-reverse;

            .collections__list {
                margin-right: 15px;
                max-width: 710px;

                @media (max-width: 992px) {
                    margin-right: initial;
                    max-width: initial;
                }
            }

            .collections__card {
                margin-left: 15px;
                margin-right: initial;

                @media (max-width: 992px) {
                    margin-left: initial;
                }
            }
        }

        @media (max-width: 992px) {
            flex-direction: column;
            padding: 20px 15px;
        }
    }

    &__list {
        p {
            line-height: 160%;
            opacity: 0.85;
        }

        p + p {
            margin-top: 20px;
        }

        @media (max-width: 992px) {
            margin-top: 30px;
        }
    }

    &__card {
        min-height: 324px;
        overflow: hidden;
        margin-right: 15px;
        border-radius: 12px;
        padding: 20px 0;
        width: 100%;
        max-width: 350px;
        background-image: linear-gradient(50.58deg, #080924 -3.93%, #151638 106.84%);
        box-shadow: 10px 15px 45px rgba(4, 4, 15, 0.65), -15px -20px 60px rgba(170, 171, 200, 0.12);


        &-image {
            margin: 0 auto 25px;
            width: 100%;
            max-width: 112px;

            img {
                width: 100%;
                height: auto;
                object-fit: cover;
            }
        }

        &-title {
            font-family: "Audiowide", sans-serif;
            font-size: fluid-font(2.4rem, 1.8rem);
            line-height: 150%;
            opacity: 0.9;
        }

        &-text {
            max-width: 308px;
            margin: 5px auto 0;
            font-size: 1.4rem;
            line-height: 160%;
            letter-spacing: -0.01em;
            text-align: center;
            opacity: 0.8;
        }

        &-link {
            display: inline-block;
            margin-top: 20px;
            font-family: "Audiowide", sans-serif;
            color: var(--clr-primary-400);
        }

        &-inner {
            text-align: center;
        }

        @media (max-width: 992px) {
            min-height: initial;
            margin-right: initial;
            max-width: initial;
        }

        &-link {
            position: relative;
            display: inline-block;

            &::before {
                content: "";
                position: absolute;
                left: 50%;
                bottom: -5px;
                width: 0;
                height: 2px;
                background-color: var(--clr-primary-400);
                transform: translateX(-50%);
                transition: width $ms;
            }

            @media (hover) {
                &:hover {
                    &::before {
                        width: 100%;
                        transition: width $ms;
                    }
                }
            }
        }
    }

    &__decor {
        position: absolute;
        right: -250px;
        top: 0;
        z-index: -2;
        border-radius: 120px;
        width: 578px;
        height: 578px;
        box-shadow: 20px 40px 100px rgba(4, 4, 15, 0.75), -40px -15px 150px rgba(170, 171, 200, 0.15);
        background-image: linear-gradient(50.58deg, #080924 -3.93%, #151638 106.84%);
        transform: rotate(-45deg);

        @media (max-width: 992px) {
            display: none;
        }
    }

    &__step {
        border-left: 3px solid var(--clr-primary-400);
        padding-left: 20px;
        max-width: 696px;

        h3 {
            margin-bottom: 10px;
        }

        &-text {
            line-height: 160%;
            opacity: 0.85;
        }

        @media (max-width: 992px) {
            max-width: initial;
        }
    }

    &__item + &__item {
        margin-top: 40px;

        @media (max-width: 992px) {
            margin-top: 21px;
        }
    }

    &__step + &__step {
        margin-top: 25px;
    }

    @media (max-width: 767px) {
        padding-bottom: 50px;
    }
}