.artists {

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 1200px) {
            flex-direction: column-reverse;
        }
    }

    &__list {
        margin-right: 15px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 16px;

        @media (max-width: 1200px) {
            margin-top: 20px;
            margin-right: initial;
        }

        @media (max-width: 767px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 576px) {
            display: block;
        }
    }

    &__item {
        padding: 13px;
        background-color: var(--clr-bg-900);

        &-wrapper {
            padding: 12px;
            background-image: linear-gradient(50.58deg, #080924 -3.93%, #151638 106.84%);
            box-shadow: 10px 15px 45px rgba(4, 4, 15, 0.65), -15px -20px 60px rgba(170, 171, 200, 0.12);
            border-radius: 12px;
            height: 100%;

            @media (max-width: 1200px) {
                padding: 9px;
            }
        }
        &-inner {
            position: relative;
            display: grid;
            align-items: center;
            grid-column-gap: 20px;
            grid-template-columns: 68px auto;
            margin-bottom: 20px;
            padding-bottom: 20px;

            &::after {
                content: '';
                position: absolute;
                left: 50%;
                bottom: 0;
                border: 0.3px solid var(--clr-default-100);
                width: 102px;
                height: 2px;
                background-color: rgba(255, 255, 255, 0.5);
                transform: translateX(-50%);
            }

            p {
                font-size: 1.4rem;
                line-height: 115%;
                opacity: 0.8;
            }
        }

        &:nth-child(odd):last-child {
            @media (max-width: 767px) {
                grid-column: 1/3;
            }
        }

        &-img {
            position: relative;
            padding-top: 100%;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: auto;
                object-fit: cover;
            }

            @media (max-width: 992px) {
                margin-right: initial;
            }
        }

        &-title {
            margin-bottom: 5px;
            font-family: "Audiowide", sans-serif;
            font-size: fluid-font(2rem, 1.8rem);
            line-height: 120%;
            opacity: 0.9;

            @media (max-width: 992px) {
                max-width: initial;
            }
        }

        &-text {
            text-align: center;
            line-height: 160%;
            letter-spacing: -0.01em;
            opacity: 0.9;
            font-size: 1.6rem;

            p + p {
                margin-top: 15px;
            }
        }

        @media (max-width: 1200px) {
            padding: 5px;
        }

        @media (max-width: 767px) {
            max-width: initial;
        }
    }

    &__item + &__item {
        @media (max-width: 576px) {
            margin-top: 21px;
        }
    }

    &__info {
        width: 100%;
        margin-left: 15px;

        @media (max-width: 1200px) {
            width: 100%;
            margin-left: initial;
        }
    }

    &__text {
        line-height: 160%;
        opacity: 0.9;
    }
}