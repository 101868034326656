$burgerMQ: 992px;
.header {
    position: absolute;
    z-index: 10;
    width: 100%;
    transition: background-color $ms;

    &__wrapper {
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__logo {
        max-width: 228px;

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }

    &__nav {
        @media (max-width: $burgerMQ) {
            position: fixed;
            right: -100%;
            top: 0;
            z-index: 10;
            display: flex;
            overflow: auto;
            width: 100%;
            height: 100%;
            max-width: 320px;
            background-color: var(--clr-default-900);
            transition: right $ms;

            &--active {
                right: 0;
                transition: right $ms;
            }
        }
    }

    &__nav-list {
        display: flex;
        align-items: center;

        @media (max-width: $burgerMQ) {
            flex-direction: column;
            margin: auto;
            padding-block: 40px;
        }
    }

    &__nav-item + &__nav-item {
        margin-left: 29px;

        @media (max-width: 1200px) {
            margin-left: 14px;
        }
        @media (max-width: $burgerMQ) {
            margin-left: 0;
            margin-top: 30px;
        }
    }

    &__nav-link {
        padding: 9px 8px;
        transition: background-image $ms, box-shadow $ms, border-radius $ms;

        @media (hover) {
            &:hover {
                background-image: linear-gradient(82.65deg, #080924 13.68%, #151638 75.39%);
                box-shadow: 3px 5px 10px rgba(3, 3, 13, 0.85), -3px -5px 10px rgba(170, 171, 200, 0.14);
                border-radius: 12px;

                transition: all $ms;
            }
        }


        @media (max-width: $burgerMQ) {
            font-size: 2.8rem;
        }
    }

    &__burger {
        $width: 25px;
        $height: 20px;
        $bgColor: var(--clr-default-100);
        $burgerLineHeight: 4px;

        display: none;

        @media (max-width: $burgerMQ) {
            position: relative;
            z-index: 15;
            display: block;
            width: $width;
            height: $height;
            background-color: transparent;

            &::after,
            &::before,
            span {
                content: "";
                position: absolute;
                left: 0;
                width: 100%;
                height: $burgerLineHeight;
                background-color: $bgColor;
            }

            &::before {
                top: 0;
                transition: transform $ms, top $ms;
            }
            &::after {
                bottom: 0;
                transition: transform $ms, bottom $ms;
            }
            span {
                top: 8px;
                transition: transform $ms;
            }

            &--active {
                span {
                    transform: scale(0);
                    transition: transform $ms;
                }

                &::before {
                    top: 8px;
                    transform: rotate(45deg);
                    transition: transform $ms, top $ms;
                }

                &::after {
                    bottom: 8px;
                    transform: rotate(-45deg);
                    transition: transform $ms, bottom $ms;
                }
            }
        }
    }

    //Fixed menu

    &--fixed {
        position: fixed;
        z-index: 11;
        background-color: var(--clr-bg-900);
        transition: background-color $ms;
    }
}
