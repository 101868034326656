.mission {
    position: relative;

    &__text {
        margin-top: 25px;
        margin-bottom: 60px;
        max-width: 779px;
        opacity: 0.9;

        @media (max-width: 767px) {
            margin-bottom: 15px;
        }
    }

    &__title {
        margin-bottom: 60px;
        font-size: fluid-font(2.8rem, 2rem);
        line-height: 95%;
        opacity: 0.9;
        font-weight: 700;

        @media (max-width: 767px) {
            margin-bottom: 20px;
        }
    }

    &__list {
        display: grid;
        grid-gap: 45px 30px;
        grid-template-columns: repeat(3, 1fr);

        @media (max-width: 1200px) {
            grid-gap: 15px;
        }

        @media (max-width: 767px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 576px) {
            display: block;
        }
    }

    &__item {
        position: relative;
        border-radius: 12px;
        padding: 115px 20px 20px;
        box-shadow: 10px 15px 45px rgba(4, 4, 15, 0.65), -15px -20px 60px rgba(170, 171, 200, 0.12);
        background-image: linear-gradient(50.58deg, #080924 -3.93%, #151638 106.84%);

        &::before {
            content: "";
            position: absolute;
            left: 50%;
            top: 20px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            transform: translateX(-50%);

            @media (max-width: 992px) {
                top: 10px;
            }
        }

        &-text {
            font-size: 1.6rem;
            line-height: 150%;
            text-align: center;
            opacity: 0.85;

            span {
                font-weight: 500;
                opacity: 1;
            }
        }

        &:nth-child(odd):last-child {
            @media (max-width: 767px) {
                grid-column: 1/3;
            }
        }

        &--1 {
            &::before {
                width: 142px;
                height: 66px;
                background-image: url("../img/mission/img1@1x.svg");
            }
        }

        &--2 {
            &::before {
                width: 94px;
                height: 72px;
                background-image: url("../img/mission/img2@1x.svg");
            }
        }

        &--3 {
            &::before {
                width: 50px;
                height: 65px;
                background-image: url("../img/mission/img3@1x.svg");
            }
        }

        @media (max-width: 992px) {
            padding: 100px 10px 10px;
        }
    }

    &__bottom {
        border-radius: 12px;
        margin-top: 45px;
        padding: 30px 20px;
        box-shadow: -15px -20px 40px rgba(151, 152, 183, 0.14), 10px 30px 50px rgba(2, 3, 13, 0.65);
        background-image: linear-gradient(50.58deg, #101241 -3.93%, #23278a 106.84%);

        &-inner {
            max-width: 955px;
            display: flex;
            align-items: center;
            margin-right: auto;
            margin-left: auto;

            @media (max-width: 576px) {
                flex-direction: column;
            }
        }

        &-img {
            max-width: 111px;
            margin-right: 15px;
            width: 100%;

            img {
                width: 100%;
                height: auto;
                object-fit: cover;
            }

            @media (max-width: 576px) {
                margin-bottom: 15px;
                margin-right: initial;
            }
        }

        &-text {
            font-size: 16px;
            line-height: 150%;
            opacity: 0.9;
        }

        &-title {
            margin-top: 10px;
            font-size: fluid-font(2.4rem, 1.8rem);
            line-height: 150%;
            letter-spacing: -0.01em;
            font-weight: 700;
        }

        @media (max-width: 1200px) {
            margin-top: 30px;
        }

        @media (max-width: 992px) {
            margin-top: 15px;
        }
    }

    &__decor {
        position: absolute;
        right: -600px;
        top: -120px;
        z-index: -2;
        width: 732px;
        height: 732px;
        background-image: linear-gradient(50.58deg, #080924 -3.93%, #151638 106.84%);
        box-shadow: 20px 40px 100px rgba(4, 4, 15, 0.75), -40px -15px 150px rgba(170, 171, 200, 0.15);
        border-radius: 160px;
        transform: rotate(40deg);

        @media (max-width: 992px) {
            display: none;
        }
    }

    &__item + &__item {
        @media (max-width: 576px) {
            margin-top: 15px;
        }
    }
}