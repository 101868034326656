@font-face {
  font-family: "Audiowide";
  src: url("../fonts/Audiowide-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "GothamPro";
  src: url("../fonts/GothamPro-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "GothamPro";
  src: url("../fonts/GothamPro-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "GothamPro";
  src: url("../fonts/GothamPro.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

:root {
  --clr-default-100: #fff;
  --clr-default-800: #2f2f33;
  --clr-default-900: #000;
  --clr-primary-400: #fad442;
  --clr-primary-500: #ef872c;
  --clr-bg-900: #080924;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  line-height: 1.15;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

html,
body {
  height: 100%;
}

body {
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

* {
  padding: 0px;
  margin: 0px;
  border: 0px;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus,
:active {
  outline: none;
}

hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

aside,
nav,
footer,
header,
section,
main {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
}

pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

a {
  background-color: transparent;
  text-decoration: none;
  color: inherit;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:focus,
a:active {
  outline: none;
}

abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
  vertical-align: top;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

button,
input {
  /* 1 */
  overflow: visible;
}

button,
select {
  /* 1 */
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

button {
  cursor: pointer;
  background-color: transparent;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

ul li {
  list-style: none;
}

body {
  font-family: "GothamPro", sans-serif;
  font-weight: 400;
  font-size: 1.8rem;
  color: var(--clr-default-100);
  background-color: var(--clr-bg-900);
}

body.lock {
  overflow: hidden;
}

body.lock--wrapper .wrapper::before {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s, visibility 0.3s;
}

.wrapper {
  overflow: hidden;
  width: 100%;
  min-width: 320px;
  min-height: 100%;
}

.wrapper::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.container {
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
  max-width: 120rem;
}

.section__pd--t170 {
  padding-top: 170px;
}

.section__title {
  font-family: "Audiowide", sans-serif;
}

.section__title--large {
  margin-bottom: 25px;
  font-size: clamp(2.2rem, 1.47273rem + 2.27273vw, 4.2rem);
  line-height: 130%;
}

.section__title--small {
  font-size: 1.8rem;
  line-height: 120%;
}

.section__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 5px;
  padding: 0 15px;
  height: 56px;
  font-family: "Audiowide", sans-serif;
  line-height: 120%;
  transition: box-shadow 0.3s;
}

.section__btn--yellow {
  color: var(--clr-default-800);
  background-image: linear-gradient(273.33deg, #ef872c -6.09%, #fcdf45 100%);
}

.section__btn--blue {
  color: var(--clr-primary-400);
  background-image: linear-gradient(82.65deg, #080924 13.68%, #151638 75.39%);
}

.section__border {
  position: relative;
  border-radius: 5px;
}

.section__border::after {
  content: "";
  position: absolute;
  left: -3px;
  top: -3px;
  z-index: -1;
  border-radius: 0.25em;
  width: calc(100% + 6px);
  height: calc(100% + 6px);
  background-size: 200%;
  animation: animateborder 3s ease infinite paused;
}

.section__border--tl::after {
  background-image: linear-gradient(145.72deg, #1A3C86 2.31%, #00923F 11.98%, rgba(246, 201, 0, 0.7) 21.87%, rgba(169, 40, 44, 0.35) 31.25%, rgba(197, 45, 42, 0.2) 39.06%, rgba(216, 48, 61, 0) 100%);
}

.section__border--tr::after {
  background-image: linear-gradient(217.8deg, #1A3C86 2.31%, #00923F 11.98%, rgba(246, 201, 0, 0.7) 21.88%, rgba(169, 40, 44, 0.35) 31.25%, rgba(197, 45, 42, 0.2) 39.06%, rgba(216, 48, 61, 0) 100%);
}

.section__border--br::after {
  background-image: linear-gradient(325.17deg, #1A3C86 0.49%, #00923F 10.21%, rgba(246, 201, 0, 0.7) 20.16%, rgba(169, 40, 44, 0.35) 29.58%, rgba(197, 45, 42, 0.2) 37.44%, rgba(216, 48, 61, 0) 98.7%);
}

.collection {
  padding-top: 120px;
  padding-bottom: 150px;
}

.collection__wrapper {
  position: relative;
}

.collection__breadcrumbs {
  margin-bottom: 10px;
}

.collection__breadcrumbs-list {
  display: flex;
  align-items: center;
  font-size: clamp(1.6rem, 1.52727rem + 0.22727vw, 1.8rem);
}

.collection__breadcrumbs-item:not(:last-child) {
  position: relative;
  margin-right: 20px;
  padding-right: 24px;
}

.collection__breadcrumbs-item:not(:last-child)::before {
  content: ">";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.collection__title {
  margin-top: 10px;
  margin-bottom: 20px;
}

.collection__text {
  margin-bottom: 45px;
}

.collection__text p + p {
  margin-top: 30px;
}

.collection__title {
  font-family: "Audiowide", sans-serif;
  font-size: clamp(3.2rem, 2.54545rem + 2.04545vw, 5rem);
  line-height: 120%;
}

.collection__cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}

.collection__cards-card {
  background-color: var(--clr-bg-900);
  padding: 10px 10px 20px;
  box-shadow: 0px 12px 25px rgba(4, 4, 13, 0.4);
}

.collection__cards-card-img {
  margin-right: auto;
  margin-left: auto;
  position: relative;
  padding-top: 100%;
}

.collection__cards-card-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.collection__cards-card-img-video {
  padding-top: 40%;
}

.collection__cards-card-img-video iframe {
  max-width: 250px;
  width: 100%;
  height: auto;
}

.collection__cards-card-title {
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: "Audiowide", sans-serif;
  font-size: clamp(1.8rem, 1.69091rem + 0.34091vw, 2.1rem);
  line-height: 130%;
  opacity: 0.9;
}

.collection__cards-card-text {
  font-size: 1.6rem;
  line-height: 145%;
  opacity: 0.8;
}

.collection__decor {
  position: absolute;
  z-index: -2;
}

.collection__decor--t {
  left: -70%;
  top: -75%;
  width: 1125px;
  height: 1125px;
  background-image: linear-gradient(50.58deg, #080924 -3.93%, #151638 106.84%);
  box-shadow: 40px 60px 150px rgba(4, 4, 15, 0.75), -15px -20px 60px rgba(170, 171, 200, 0.12);
  border-radius: 300px;
  transform: rotate(45deg);
}

.collection__decor--b {
  right: -50%;
  bottom: 0;
  border-radius: 120px;
  width: 578px;
  height: 578px;
  box-shadow: 20px 40px 100px rgba(4, 4, 15, 0.75), -40px -15px 150px rgba(170, 171, 200, 0.15);
  background-image: linear-gradient(50.58deg, #080924 -3.93%, #151638 106.84%);
  transform: rotate(-45deg);
}

@keyframes animateborder {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

.header {
  position: absolute;
  z-index: 10;
  width: 100%;
  transition: background-color 0.3s;
}

.header__wrapper {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__logo {
  max-width: 228px;
}

.header__logo img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.header__nav-list {
  display: flex;
  align-items: center;
}

.header__nav-item + .header__nav-item {
  margin-left: 29px;
}

.header__nav-link {
  padding: 9px 8px;
  transition: background-image 0.3s, box-shadow 0.3s, border-radius 0.3s;
}

.header__burger {
  display: none;
}

.header--fixed {
  position: fixed;
  z-index: 11;
  background-color: var(--clr-bg-900);
  transition: background-color 0.3s;
}

.hero {
  padding-top: 90px;
}

.hero__text {
  line-height: 160%;
}

.hero__text p + p {
  margin-top: 30px;
}

.hero__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hero__info {
  flex: 0 1 49%;
  max-width: 527px;
  margin-right: 15px;
}

.hero__title {
  margin-bottom: 35px;
  font-family: "Audiowide", sans-serif;
  font-size: clamp(3rem, 2.27273rem + 2.27273vw, 5rem);
  line-height: 120%;
}

.hero__title span {
  color: var(--clr-primary-500);
}

.hero__btns {
  margin-top: 45px;
  display: flex;
  align-items: center;
}

.hero .section__btn--yellow {
  min-width: 225px;
}

.hero .section__btn--blue {
  margin-left: 30px;
  min-width: 180px;
}

.hero__decor {
  position: absolute;
  left: -450px;
  top: -90%;
  z-index: -2;
  width: 1125px;
  height: 1125px;
  background-image: linear-gradient(50.58deg, #080924 -3.93%, #151638 106.84%);
  box-shadow: 40px 60px 150px rgba(4, 4, 15, 0.75), -15px -20px 60px rgba(170, 171, 200, 0.12);
  border-radius: 300px;
  transform: rotate(45deg);
}

.hero__img {
  flex: 0 1 49%;
  position: relative;
  max-width: 474px;
  z-index: 1;
}

.hero__img img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.collections {
  position: relative;
  padding-top: 50px;
  padding-bottom: 95px;
}

.collections__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 55px 25px;
  background-color: var(--clr-bg-900);
}

.collections__item--reverse {
  flex-direction: row-reverse;
}

.collections__item--reverse .collections__list {
  margin-right: 15px;
  max-width: 710px;
}

.collections__item--reverse .collections__card {
  margin-left: 15px;
  margin-right: initial;
}

.collections__list p {
  line-height: 160%;
  opacity: 0.85;
}

.collections__list p + p {
  margin-top: 20px;
}

.collections__card {
  min-height: 324px;
  overflow: hidden;
  margin-right: 15px;
  border-radius: 12px;
  padding: 20px 0;
  width: 100%;
  max-width: 350px;
  background-image: linear-gradient(50.58deg, #080924 -3.93%, #151638 106.84%);
  box-shadow: 10px 15px 45px rgba(4, 4, 15, 0.65), -15px -20px 60px rgba(170, 171, 200, 0.12);
}

.collections__card-image {
  margin: 0 auto 25px;
  width: 100%;
  max-width: 112px;
}

.collections__card-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.collections__card-title {
  font-family: "Audiowide", sans-serif;
  font-size: clamp(1.8rem, 1.58182rem + 0.68182vw, 2.4rem);
  line-height: 150%;
  opacity: 0.9;
}

.collections__card-text {
  max-width: 308px;
  margin: 5px auto 0;
  font-size: 1.4rem;
  line-height: 160%;
  letter-spacing: -0.01em;
  text-align: center;
  opacity: 0.8;
}

.collections__card-link {
  display: inline-block;
  margin-top: 20px;
  font-family: "Audiowide", sans-serif;
  color: var(--clr-primary-400);
}

.collections__card-inner {
  text-align: center;
}

.collections__card-link {
  position: relative;
  display: inline-block;
}

.collections__card-link::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -5px;
  width: 0;
  height: 2px;
  background-color: var(--clr-primary-400);
  transform: translateX(-50%);
  transition: width 0.3s;
}

.collections__decor {
  position: absolute;
  right: -250px;
  top: 0;
  z-index: -2;
  border-radius: 120px;
  width: 578px;
  height: 578px;
  box-shadow: 20px 40px 100px rgba(4, 4, 15, 0.75), -40px -15px 150px rgba(170, 171, 200, 0.15);
  background-image: linear-gradient(50.58deg, #080924 -3.93%, #151638 106.84%);
  transform: rotate(-45deg);
}

.collections__step {
  border-left: 3px solid var(--clr-primary-400);
  padding-left: 20px;
  max-width: 696px;
}

.collections__step h3 {
  margin-bottom: 10px;
}

.collections__step-text {
  line-height: 160%;
  opacity: 0.85;
}

.collections__item + .collections__item {
  margin-top: 40px;
}

.collections__step + .collections__step {
  margin-top: 25px;
}

.command {
  position: relative;
}

.command__wrapper {
  display: flex;
  justify-content: space-between;
}

.command__persons {
  margin-right: 15px;
  max-width: 470px;
}

.command__text {
  margin-top: 20px;
  margin-bottom: 25px;
}

.command__title {
  font-family: "Audiowide", sans-serif;
  font-size: clamp(1.8rem, 1.58182rem + 0.68182vw, 2.4rem);
  line-height: 160%;
}

.command__person {
  font-size: 1.6rem;
}

.command__education {
  margin-right: 15px;
}

.command__person + .command__person {
  margin-top: 35px;
}

.command__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0.9;
}

.command__inner span {
  font-weight: 700;
}

.command__cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
  align-items: flex-start;
}

.command__card {
  padding: 15px 10px 30px;
  background-image: linear-gradient(50.58deg, #080924 -3.93%, #151638 106.84%);
  box-shadow: -5px -10px 50px rgba(219, 219, 230, 0.1), 12px 20px 35px rgba(3, 3, 13, 0.8);
  border-radius: 5px;
}

.command__card-title {
  margin-top: 20px;
  font-family: "Audiowide", sans-serif;
  font-size: clamp(1.8rem, 1.69091rem + 0.34091vw, 2.1rem);
  line-height: 130%;
  opacity: 0.9;
  text-align: center;
}

.command__img {
  position: relative;
  padding-top: 100%;
  border-radius: 4px;
}

.command__img img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.command__decor {
  position: absolute;
  left: -200px;
  top: 200px;
  width: 667px;
  height: 667px;
  border-radius: 160px;
  z-index: -2;
  background-image: linear-gradient(50.58deg, #080924 -3.93%, #151638 106.84%);
  box-shadow: 20px 40px 100px rgba(4, 4, 15, 0.75), -40px -15px 150px rgba(170, 171, 200, 0.15);
  transform: rotate(23deg);
}

.teachers__list {
  display: grid;
  grid-gap: 36px;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 50px;
}

.teachers__text {
  max-width: 870px;
  opacity: 0.9;
}

.teachers__item {
  padding: 13px;
  background-color: var(--clr-bg-900);
}

.teachers__item-wrapper {
  padding: 12px;
  background-image: linear-gradient(50.58deg, #080924 -3.93%, #151638 106.84%);
  box-shadow: 10px 15px 45px rgba(4, 4, 15, 0.65), -15px -20px 60px rgba(170, 171, 200, 0.12);
  border-radius: 12px;
  height: 100%;
}

.teachers__item-inner {
  position: relative;
  display: grid;
  align-items: center;
  grid-column-gap: 30px;
  grid-template-columns: 148px auto;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.teachers__item-inner::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  border: 0.3px solid var(--clr-default-100);
  width: 48px;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.5);
  transform: translateX(-50%);
}

.teachers__item-inner p {
  font-size: 1.4rem;
  line-height: 160%;
  opacity: 0.8;
}

.teachers__item-img {
  position: relative;
  padding-top: 100%;
}

.teachers__item-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.teachers__item-info {
  margin-left: 15px;
}

.teachers__item-title {
  max-width: 139px;
  font-family: "Audiowide", sans-serif;
  font-size: clamp(1.8rem, 1.58182rem + 0.68182vw, 2.4rem);
  line-height: 135%;
  opacity: 0.9;
}

.teachers__item-text {
  line-height: 160%;
  letter-spacing: -0.01em;
  opacity: 0.9;
}

.mission {
  position: relative;
}

.mission__text {
  margin-top: 25px;
  margin-bottom: 60px;
  max-width: 779px;
  opacity: 0.9;
}

.mission__title {
  margin-bottom: 60px;
  font-size: clamp(2rem, 1.70909rem + 0.90909vw, 2.8rem);
  line-height: 95%;
  opacity: 0.9;
  font-weight: 700;
}

.mission__list {
  display: grid;
  grid-gap: 45px 30px;
  grid-template-columns: repeat(3, 1fr);
}

.mission__item {
  position: relative;
  border-radius: 12px;
  padding: 115px 20px 20px;
  box-shadow: 10px 15px 45px rgba(4, 4, 15, 0.65), -15px -20px 60px rgba(170, 171, 200, 0.12);
  background-image: linear-gradient(50.58deg, #080924 -3.93%, #151638 106.84%);
}

.mission__item::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 20px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transform: translateX(-50%);
}

.mission__item-text {
  font-size: 1.6rem;
  line-height: 150%;
  text-align: center;
  opacity: 0.85;
}

.mission__item-text span {
  font-weight: 500;
  opacity: 1;
}

.mission__item--1::before {
  width: 142px;
  height: 66px;
  background-image: url("../img/mission/img1@1x.svg");
}

.mission__item--2::before {
  width: 94px;
  height: 72px;
  background-image: url("../img/mission/img2@1x.svg");
}

.mission__item--3::before {
  width: 50px;
  height: 65px;
  background-image: url("../img/mission/img3@1x.svg");
}

.mission__bottom {
  border-radius: 12px;
  margin-top: 45px;
  padding: 30px 20px;
  box-shadow: -15px -20px 40px rgba(151, 152, 183, 0.14), 10px 30px 50px rgba(2, 3, 13, 0.65);
  background-image: linear-gradient(50.58deg, #101241 -3.93%, #23278a 106.84%);
}

.mission__bottom-inner {
  max-width: 955px;
  display: flex;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
}

.mission__bottom-img {
  max-width: 111px;
  margin-right: 15px;
  width: 100%;
}

.mission__bottom-img img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.mission__bottom-text {
  font-size: 16px;
  line-height: 150%;
  opacity: 0.9;
}

.mission__bottom-title {
  margin-top: 10px;
  font-size: clamp(1.8rem, 1.58182rem + 0.68182vw, 2.4rem);
  line-height: 150%;
  letter-spacing: -0.01em;
  font-weight: 700;
}

.mission__decor {
  position: absolute;
  right: -600px;
  top: -120px;
  z-index: -2;
  width: 732px;
  height: 732px;
  background-image: linear-gradient(50.58deg, #080924 -3.93%, #151638 106.84%);
  box-shadow: 20px 40px 100px rgba(4, 4, 15, 0.75), -40px -15px 150px rgba(170, 171, 200, 0.15);
  border-radius: 160px;
  transform: rotate(40deg);
}

.network {
  position: relative;
  padding-bottom: 170px;
}

.network__title {
  margin-bottom: 45px;
  font-family: "Audiowide", sans-serif;
  font-size: clamp(1.8rem, 1.58182rem + 0.68182vw, 2.4rem);
  line-height: 135%;
  letter-spacing: 0.1em;
  opacity: 0.9;
}

.network__subtitle {
  margin-bottom: 15px;
  font-weight: 500;
  font-size: clamp(2rem, 1.74545rem + 0.79545vw, 2.7rem);
  line-height: 160%;
}

.network__text {
  opacity: .85;
  margin-bottom: 60px;
}

.network__text p + p {
  margin-top: 30px;
}

.network__decor {
  position: absolute;
  left: -450px;
  top: 0;
  z-index: -2;
  border-radius: 120px;
  width: 578px;
  height: 578px;
  box-shadow: 20px 40px 100px rgba(4, 4, 15, 0.75), -40px -15px 150px rgba(170, 171, 200, 0.15);
  background-image: linear-gradient(50.58deg, #080924 -3.93%, #151638 106.84%);
  transform: rotate(-45deg);
}

.network .section__btn--blue {
  min-width: 179px;
}

.artists__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.artists__list {
  margin-right: 15px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
}

.artists__item {
  padding: 13px;
  background-color: var(--clr-bg-900);
}

.artists__item-wrapper {
  padding: 12px;
  background-image: linear-gradient(50.58deg, #080924 -3.93%, #151638 106.84%);
  box-shadow: 10px 15px 45px rgba(4, 4, 15, 0.65), -15px -20px 60px rgba(170, 171, 200, 0.12);
  border-radius: 12px;
  height: 100%;
}

.artists__item-inner {
  position: relative;
  display: grid;
  align-items: center;
  grid-column-gap: 20px;
  grid-template-columns: 68px auto;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.artists__item-inner::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  border: 0.3px solid var(--clr-default-100);
  width: 102px;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.5);
  transform: translateX(-50%);
}

.artists__item-inner p {
  font-size: 1.4rem;
  line-height: 115%;
  opacity: 0.8;
}

.artists__item-img {
  position: relative;
  padding-top: 100%;
}

.artists__item-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.artists__item-title {
  margin-bottom: 5px;
  font-family: "Audiowide", sans-serif;
  font-size: clamp(1.8rem, 1.72727rem + 0.22727vw, 2rem);
  line-height: 120%;
  opacity: 0.9;
}

.artists__item-text {
  text-align: center;
  line-height: 160%;
  letter-spacing: -0.01em;
  opacity: 0.9;
  font-size: 1.6rem;
}

.artists__item-text p + p {
  margin-top: 15px;
}

.artists__info {
  width: 100%;
  margin-left: 15px;
}

.artists__text {
  line-height: 160%;
  opacity: 0.9;
}

.footer {
  padding-top: 50px;
  padding-bottom: 60px;
  background-image: linear-gradient(50.58deg, #080924 -3.93%, #151638 106.84%);
  box-shadow: -15px -20px 40px rgba(151, 152, 183, 0.09), 10px 30px 60px rgba(2, 3, 13, 0.65);
  border-radius: 12px;
}

.footer .section__btn--yellow {
  height: 50px;
  border-bottom-left-radius: initial;
  border-top-left-radius: initial;
}

.footer__top {
  display: flex;
  justify-content: space-between;
  padding-bottom: 50px;
  border-bottom: 1px solid #393a50;
}

.footer__top-left {
  flex: 0 1 49%;
  max-width: 524px;
  width: 100%;
  margin-right: 15px;
}

.footer__top-input {
  display: flex;
}

.footer__top-input input {
  padding-left: 10px;
  padding-right: 10px;
  max-width: 400px;
  width: 100%;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  color: var(--clr-default-100);
}

.footer__top-list {
  flex: 0 1 49%;
  display: grid;
  grid-gap: 30px 40px;
  grid-template-columns: repeat(3, 1fr);
}

.footer__top-title {
  margin-top: 30px;
  margin-bottom: 30px;
  font-family: "Audiowide", sans-serif;
  font-size: clamp(1.8rem, 1.58182rem + 0.68182vw, 2.4rem);
  line-height: 130%;
}

.footer__top-link {
  font-size: 1.6rem;
  line-height: 95%;
}

.footer__bottom {
  padding-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer__bottom-copyright {
  margin-bottom: 15px;
  font-size: 1.6rem;
  line-height: 115%;
  color: rgba(255, 255, 255, 0.65);
}

.footer__social-list {
  display: flex;
  align-items: center;
}

.footer__social-item + .footer__social-item {
  margin-left: 25px;
}

.footer__social-item svg {
  fill: var(--clr-default-100);
  transition: fill 0.3s;
}

@media (max-width: 1200px) {
  .collection__cards {
    grid-gap: 15px;
  }

  .header__nav-item + .header__nav-item {
    margin-left: 14px;
  }

  .command__cards {
    grid-gap: 15px;
  }

  .teachers__item-wrapper {
    padding: 9px;
  }

  .teachers__item-inner {
    grid-template-columns: 100px auto;
  }

  .teachers__item {
    padding: 5px;
  }

  .mission__list {
    grid-gap: 15px;
  }

  .mission__bottom {
    margin-top: 30px;
  }

  .artists__wrapper {
    flex-direction: column-reverse;
  }

  .artists__list {
    margin-top: 20px;
    margin-right: initial;
  }

  .artists__item-wrapper {
    padding: 9px;
  }

  .artists__item {
    padding: 5px;
  }

  .artists__info {
    width: 100%;
    margin-left: initial;
  }

  .footer__top-list {
    grid-gap: 15px;
  }

  .footer__social-item + .footer__social-item {
    margin-left: 25px;
  }
}

@media (max-width: 992px) {
  .section__pd--t170 {
    padding-top: 100px;
  }

  .collection__cards {
    grid-template-columns: repeat(3, 1fr);
  }

  .collection__decor {
    display: none;
  }

  .header__nav {
    position: fixed;
    right: -100%;
    top: 0;
    z-index: 10;
    display: flex;
    overflow: auto;
    width: 100%;
    height: 100%;
    max-width: 320px;
    background-color: var(--clr-default-900);
    transition: right 0.3s;
  }

  .header__nav--active {
    right: 0;
    transition: right 0.3s;
  }

  .header__nav-list {
    flex-direction: column;
    margin: auto;
    padding-block: 40px;
  }

  .header__nav-item + .header__nav-item {
    margin-left: 0;
    margin-top: 30px;
  }

  .header__nav-link {
    font-size: 2.8rem;
  }

  .header__burger {
    position: relative;
    z-index: 15;
    display: block;
    width: 25px;
    height: 20px;
    background-color: transparent;
  }

  .header__burger::after,
  .header__burger::before,
  .header__burger span {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: var(--clr-default-100);
  }

  .header__burger::before {
    top: 0;
    transition: transform 0.3s, top 0.3s;
  }

  .header__burger::after {
    bottom: 0;
    transition: transform 0.3s, bottom 0.3s;
  }

  .header__burger span {
    top: 8px;
    transition: transform 0.3s;
  }

  .header__burger--active span {
    transform: scale(0);
    transition: transform 0.3s;
  }

  .header__burger--active::before {
    top: 8px;
    transform: rotate(45deg);
    transition: transform 0.3s, top 0.3s;
  }

  .header__burger--active::after {
    bottom: 8px;
    transform: rotate(-45deg);
    transition: transform 0.3s, bottom 0.3s;
  }

  .hero__decor {
    display: none;
  }

  .collections {
    padding-top: 70px;
  }

  .collections__item--reverse .collections__list {
    margin-right: initial;
    max-width: initial;
  }

  .collections__item--reverse .collections__card {
    margin-left: initial;
  }

  .collections__item {
    flex-direction: column;
    padding: 20px 15px;
  }

  .collections__list {
    margin-top: 30px;
  }

  .collections__card {
    min-height: initial;
    margin-right: initial;
    max-width: initial;
  }

  .collections__decor {
    display: none;
  }

  .collections__step {
    max-width: initial;
  }

  .collections__item + .collections__item {
    margin-top: 21px;
  }

  .command__wrapper {
    flex-direction: column;
  }

  .command__persons {
    margin-right: initial;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: initial;
    grid-gap: 15px;
  }

  .command__text {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .command__education {
    margin-right: initial;
  }

  .command__person + .command__person {
    margin-top: initial;
  }

  .command__inner {
    flex-direction: column;
    align-items: flex-start;
  }

  .command__cards {
    max-width: initial;
  }

  .command__decor {
    display: none;
  }

  .teachers__list {
    grid-gap: 21px;
    grid-template-columns: repeat(2, 1fr);
  }

  .teachers__item-inner {
    grid-template-columns: 70px auto;
  }

  .teachers__item-info {
    margin-left: initial;
  }

  .teachers__item-title {
    margin-top: 15px;
    max-width: initial;
  }

  .mission__item::before {
    top: 10px;
  }

  .mission__item {
    padding: 100px 10px 10px;
  }

  .mission__bottom {
    margin-top: 15px;
  }

  .mission__decor {
    display: none;
  }

  .network {
    padding-bottom: 100px;
  }

  .network__decor {
    display: none;
  }

  .artists__item-img {
    margin-right: initial;
  }

  .artists__item-title {
    max-width: initial;
  }

  .footer__top {
    flex-direction: column;
  }

  .footer__top-left {
    margin-right: initial;
    max-width: initial;
  }

  .footer__top-list {
    margin-top: 20px;
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 767px) {
  .section__pd--t170 {
    padding-top: 50px;
  }

  .section__title--large {
    margin-bottom: 15px;
  }

  .collection {
    padding-top: 100px;
    padding-bottom: 70px;
  }

  .collection__text p + p {
    margin-top: 15px;
  }

  .collection__text {
    margin-bottom: 20px;
  }

  .collection__cards {
    grid-template-columns: repeat(2, 1fr);
  }

  .hero__text p {
    text-align: center;
  }

  .hero__wrapper {
    flex-direction: column;
  }

  .hero__info {
    max-width: initial;
    margin-right: initial;
  }

  .hero__title {
    margin-bottom: 20px;
    text-align: center;
  }

  .hero__btns {
    margin-top: 20px;
    justify-content: center;
  }

  .hero__img {
    margin-top: 20px;
    max-width: 350px;
  }

  .collections {
    padding-bottom: 50px;
  }

  .teachers__list {
    margin-top: 20px;
  }

  .teachers__item {
    max-width: initial;
  }

  .mission__text {
    margin-bottom: 15px;
  }

  .mission__title {
    margin-bottom: 20px;
  }

  .mission__list {
    grid-template-columns: repeat(2, 1fr);
  }

  .mission__item:nth-child(odd):last-child {
    grid-column: 1/3;
  }

  .network {
    padding-bottom: 50px;
  }

  .network__title {
    margin-bottom: 20px;
  }

  .network__text p + p {
    margin-top: 15px;
  }

  .network__text {
    margin-bottom: 30px;
  }

  .artists__list {
    grid-template-columns: repeat(2, 1fr);
  }

  .artists__item:nth-child(odd):last-child {
    grid-column: 1/3;
  }

  .artists__item {
    max-width: initial;
  }

  .footer {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .footer__top-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 576px) {
  .hero__btns {
    flex-direction: column;
  }

  .hero .section__btn + .section__btn {
    margin-top: 15px;
  }

  .hero .section__btn--blue {
    margin-left: initial;
  }

  .command__persons {
    display: block;
  }

  .command__person + .command__person {
    margin-top: 15px;
  }

  .command__cards {
    display: block;
  }

  .command__card + .command__card {
    margin-top: 15px;
  }

  .teachers__list {
    display: block;
  }

  .teachers__item + .teachers__item {
    margin-top: 21px;
  }

  .mission__list {
    display: block;
  }

  .mission__bottom-inner {
    flex-direction: column;
  }

  .mission__bottom-img {
    margin-bottom: 15px;
    margin-right: initial;
  }

  .mission__item + .mission__item {
    margin-top: 15px;
  }

  .artists__list {
    display: block;
  }

  .artists__item + .artists__item {
    margin-top: 21px;
  }

  .footer__top-list {
    grid-template-columns: repeat(2, 1fr);
  }

  .footer__bottom-copyright {
    margin-top: 15px;
    margin-bottom: initial;
  }

  .footer__bottom {
    flex-direction: column-reverse;
    align-items: initial;
  }
}

@media (max-width: 460px) {
  .collection__cards {
    display: block;
  }

  .collection__cards-card + .collection__cards-card {
    margin-top: 15px;
  }
}

@media (hover) {
  .section__btn--yellow:hover {
    box-shadow: 0 22px 40px rgba(251, 220, 68, 0.15);
    transition: box-shadow 0.3s;
  }

  .section__btn--blue:hover {
    box-shadow: 3px 5px 15px rgba(3, 3, 13, 0.85), -3px -5px 15px rgba(170, 171, 200, 0.1);
    transition: box-shadow 0.3s;
  }

  .section__border:hover::after {
    animation-play-state: running;
  }

  .header__nav-link:hover {
    background-image: linear-gradient(82.65deg, #080924 13.68%, #151638 75.39%);
    box-shadow: 3px 5px 10px rgba(3, 3, 13, 0.85), -3px -5px 10px rgba(170, 171, 200, 0.14);
    border-radius: 12px;
    transition: all 0.3s;
  }

  .collections__card-link:hover::before {
    width: 100%;
    transition: width 0.3s;
  }

  .footer__social-item a:hover svg {
    fill: var(--clr-primary-400);
    transition: fill 0.3s;
  }
}