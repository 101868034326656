.section {
    &__pd {
        &--t170 {
            padding-top: 170px;

            @media (max-width: 992px) {
                padding-top: 100px;
            }

            @media (max-width: 767px) {
                padding-top: 50px;
            }
        }
    }

    &__title {
        font-family: "Audiowide", sans-serif;

        &--large {
            margin-bottom: 25px;
            font-size: fluid-font(4.2rem, 2.2rem);
            line-height: 130%;

            @media (max-width: 767px) {
                margin-bottom: 15px;
            }
        }

        &--small {
            font-size: 1.8rem;
            line-height: 120%;
        }
    }

    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border-radius: 5px;
        padding: 0 15px;
        height: 56px;
        font-family: "Audiowide", sans-serif;
        line-height: 120%;
        transition: box-shadow $ms;

        &--yellow {
            color: var(--clr-default-800);
            background-image: linear-gradient(273.33deg, #ef872c -6.09%, #fcdf45 100%);

            @media (hover) {
                &:hover {
                    box-shadow: 0 22px 40px rgba(251, 220, 68, 0.15);
                    transition: box-shadow $ms;
                }
            }
        }

        &--blue {
            color: var(--clr-primary-400);
            background-image: linear-gradient(82.65deg, #080924 13.68%, #151638 75.39%);

            @media (hover) {
                &:hover {
                    box-shadow: 3px 5px 15px rgba(3, 3, 13, 0.85), -3px -5px 15px rgba(170, 171, 200, 0.1);
                    transition: box-shadow $ms;
                }
            }
        }
    }

    &__border {
        position: relative;
        border-radius: 5px;

        &::after {
            content: "";
            position: absolute;
            left: -3px;
            top: -3px;
            z-index: -1;
            border-radius: 0.25em;
            width: calc(100% + 6px);
            height: calc(100% + 6px);
            background-size: 200%;
            animation: animateborder 3s ease infinite paused;
        }

        //четный
        &--tl {
            &::after {
                background-image: linear-gradient(145.72deg, #1A3C86 2.31%, #00923F 11.98%, rgba(246, 201, 0, 0.7) 21.87%, rgba(169, 40, 44, 0.35) 31.25%, rgba(197, 45, 42, 0.2) 39.06%, rgba(216, 48, 61, 0) 100%);
            }
        }

        //нечетный
        &--tr {
            &::after {
                background-image: linear-gradient(217.8deg, #1A3C86 2.31%, #00923F 11.98%, rgba(246, 201, 0, 0.7) 21.88%, rgba(169, 40, 44, 0.35) 31.25%, rgba(197, 45, 42, 0.2) 39.06%, rgba(216, 48, 61, 0) 100%);
            }
        }

        &--br {
            &::after {
                background-image: linear-gradient(325.17deg, #1A3C86 0.49%, #00923F 10.21%, rgba(246, 201, 0, 0.7) 20.16%, rgba(169, 40, 44, 0.35) 29.58%, rgba(197, 45, 42, 0.2) 37.44%, rgba(216, 48, 61, 0) 98.7%);
            }
        }

        @media (hover) {
            &:hover {
                &::after {
                    animation-play-state: running;
                }
            }
        }
    }
}

.collection {
    padding-top: 120px;
    padding-bottom: 150px;

    @media (max-width: 767px) {
        padding-top: 100px;
        padding-bottom: 70px;
    }

    &__wrapper {
        position: relative;
    }

    &__breadcrumbs {
        margin-bottom: 10px;

        &-list {
            display: flex;
            align-items: center;
            font-size: fluid-font(1.8rem, 1.6rem);

        }

        &-item {
            &:not(:last-child) {
                position: relative;
                margin-right: 20px;
                padding-right: 24px;
            }

            &:not(:last-child)::before {
                content: ">";
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
            }
        }
    }

    &__title {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    &__text {
        margin-bottom: 45px;

        p + p {
            margin-top: 30px;

            @media (max-width: 767px) {
                margin-top: 15px;
            }
        }

        @media (max-width: 767px) {
            margin-bottom: 20px;
        }
    }

    &__title {
        font-family: "Audiowide", sans-serif;
        font-size: fluid-font(5rem, 3.2rem);
        line-height: 120%;
    }

    &__cards {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 30px;

        @media (max-width: 1200px) {
            grid-gap: 15px;
        }

        @media (max-width: 992px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media (max-width: 767px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 460px) {
            display: block;
        }

        &-card {
            background-color: var(--clr-bg-900);
            padding: 10px 10px 20px;
            box-shadow: 0px 12px 25px rgba(4, 4, 13, 0.4);

            &-img {
                margin-right: auto;
                margin-left: auto;
                position: relative;
                padding-top: 100%;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                &-video {
                    padding-top: 40%;

                    iframe {
                        max-width: 250px;
                        width: 100%;
                        height: auto;
                    }
                }
            }

            &-title {
                margin-top: 20px;
                margin-bottom: 10px;
                font-family: "Audiowide", sans-serif;
                font-size: fluid-font(2.1rem, 1.8rem);
                line-height: 130%;
                opacity: 0.9;
            }

            &-text {
                font-size: 1.6rem;
                line-height: 145%;
                opacity: 0.8;
            }
        }

        &-card + &-card {
            @media (max-width: 460px) {
                margin-top: 15px;
            }
        }
    }

    &__decor {
        position: absolute;
        z-index: -2;

        &--t {
            left: -70%;
            top: -75%;
            width: 1125px;
            height: 1125px;
            background-image: linear-gradient(50.58deg, #080924 -3.93%, #151638 106.84%);
            box-shadow: 40px 60px 150px rgba(4, 4, 15, 0.75), -15px -20px 60px rgba(170, 171, 200, 0.12);
            border-radius: 300px;
            transform: rotate(45deg);
        }

        &--b {
            right: -50%;
            bottom: 0;
            border-radius: 120px;
            width: 578px;
            height: 578px;
            box-shadow: 20px 40px 100px rgba(4, 4, 15, 0.75), -40px -15px 150px rgba(170, 171, 200, 0.15);
            background-image: linear-gradient(50.58deg, #080924 -3.93%, #151638 106.84%);
            transform: rotate(-45deg);
        }

        @media (max-width: 992px) {
            display: none;
        }
    }
}

@keyframes animateborder {
    0% {
        background-position: 0%;
    }

    50% {
        background-position: 100%;
    }

    100% {
        background-position: 0%;
    }
}