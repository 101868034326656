.network {
    position: relative;
    padding-bottom: 170px;

    @media (max-width: 992px) {
        padding-bottom: 100px;
    }

    @media (max-width: 767px) {
        padding-bottom: 50px;
    }

    &__title {
        margin-bottom: 45px;
        font-family: "Audiowide", sans-serif;
        font-size: fluid-font(2.4rem, 1.8rem);
        line-height: 135%;
        letter-spacing: 0.1em;
        opacity: 0.9;
        @media (max-width: 767px) {
            margin-bottom: 20px;
        }
    }

    &__subtitle {
        margin-bottom: 15px;
        font-weight: 500;
        font-size: fluid-font(2.7rem, 2rem);
        line-height: 160%;
    }

    &__text {
        opacity: .85;
        margin-bottom: 60px;
        p + p {
            margin-top: 30px;

            @media (max-width: 767px) {
                margin-top: 15px;
            }
        }

        @media (max-width: 767px) {
            margin-bottom: 30px;
        }
    }

    &__decor {
        position: absolute;
        left: -450px;
        top: 0;
        z-index: -2;
        border-radius: 120px;
        width: 578px;
        height: 578px;
        box-shadow: 20px 40px 100px rgba(4, 4, 15, 0.75), -40px -15px 150px rgba(170, 171, 200, 0.15);
        background-image: linear-gradient(50.58deg, #080924 -3.93%, #151638 106.84%);
        transform: rotate(-45deg);

        @media (max-width: 992px) {
            display: none;
        }
    }

    .section__btn--blue {
        min-width: 179px;
    }
}